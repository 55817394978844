<template>
  <div class="v-stack h-stretch gap-3">
    <div class="h-stack h-end">
      <button @click="$router.push('/storage/add')" class="add">Add</button>
    </div>
    <div class="h-stack h-start gap-3">
      <label style="align-self: center">Internal:</label>
      <input type="checkbox" v-model="showInternal" />
      <label style="align-self: center">External:</label>
      <input type="checkbox" v-model="showExternal" />
      <div
        class="h-stack gap-3 h-start"
        v-for="type in driveTypes"
        :key="type.type"
      >
        <label style="align-self: center">{{ type.type }}:</label>
        <input type="checkbox" v-model="type.show" />
      </div>
    </div>
    <Table
      v-on:rowSelected="(item) => $router.push('/storage/detail/' + item._id)"
      :items="filteredDrives"
      :defaultColumn="0"
      css="1fr 60px 100px 100px 70px 70px 100px"
      :columns="[
        {
          name: 'Name',
          sort: 'alphabet',
          path: ['name'],
        },
        {
          name: 'Type',
          sort: 'alphabet',
          path: ['external'],
          formatType: 'boolean',
          format: ['External', 'Internal'],
        },
        {
          name: 'Storage Type',
          sort: 'alphabet',
          path: ['type'],
        },
        {
          name: 'Form Factor',
          sort: 'alphabet',
          path: ['formFactor'],
        },
        {
          name: 'Free (TB)',
          sort: 'numeric',
          path: ['tooltipFreeSpace'],
          styleFunction: (item) => {
            if (item.tooltipFreeSpace < 0.2) {
              return 'color: red';
            }
            if (item.tooltipFreeSpace < 1) {
              return 'color: #db7f00';
            }
            if (item.tooltipFreeSpace / item.size < 0.99) {
              return 'color: green';
            }
            return 'color: green; font-weight: bold';
          },
        },
        {
          name: 'Free %',
          sort: 'numeric',
          path: ['tooltipFreeSpacePercent'],
          format: ' %',
          styleFunction: (item) => {
            if (item.tooltipFreeSpace < 0.2) {
              return 'color: red';
            }
            if (item.tooltipFreeSpace < 1) {
              return 'color: #db7f00';
            }
            if (item.tooltipFreeSpace / item.size < 0.99) {
              return 'color: green';
            }
            return 'color: green; font-weight: bold';
          },
        },
        {
          name: 'Size (TB)',
          sort: 'numeric',
          path: ['size'],
          formatMap: (size) => size.toFixed(2),
        },
      ]"
    ></Table>
  </div>
</template>

<script>
import Table from "@/components/Table.vue";
import { mapActions } from "vuex";
import constants from "@/constants.js";

export default {
  data() {
    return {
      showInternal: true,
      showExternal: true,
      drives: [],
      driveTypes: constants.driveTypes.map((type) => {
        return {
          type: type,
          show: true,
        };
      }),
    };
  },
  components: {
    Table,
  },
  methods: {
    ...mapActions(["getDrives"]),
  },
  computed: {
    filteredDrives() {
      let drives = this.drives;

      if (!this.showInternal) {
        drives = drives.filter((drive) => drive.external != false);
      }

      if (!this.showExternal) {
        drives = drives.filter((drive) => drive.external != true);
      }

      for (const type of this.driveTypes) {
        if (!type.show) {
          drives = drives.filter((drive) => drive.type != type.type);
        }
      }

      return drives;
    },
  },
  mounted() {
    this.getDrives()
      .then((drives) => {
        this.drives = drives;

        for (const drive of this.drives) {
          let usedSpace = 0;
          for (const folder of drive.folders) {
            if (folder.project) {
              usedSpace += folder.project.size / 1024;
            }
          }
          drive.tooltipFreeSpace = (drive.size - usedSpace).toFixed(2);
          drive.tooltipFreeSpacePercent =
            100 - Math.round((usedSpace / drive.size) * 100);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
